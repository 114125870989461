import "./App.css";
import { ClerkProvider, SignIn, SignUp } from "@clerk/clerk-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import FindATherapist from "./FindATherapist";
import Portal from "./Portal";

const clerkPublishableKey =
    "pk_test_Y2FzdWFsLWhhZGRvY2stNTIuY2xlcmsuYWNjb3VudHMuZGV2JA";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },

        {
            path: "/FindATherapist",
            element: <FindATherapist />,
        },

        // Clerk specific routes
        {
            path: "/sign-in/*",
            element: (
                <SignIn
                    afterSignInUrl={window.location.href + "Portal"}
                    routing="path"
                    path="/sign-in"
                />
            ),
        },
        {
            path: "/sign-up/*",
            element: (
                <SignUp
                    afterSignInUrl={window.location.href + "Portal"}
                    routing="path"
                    path="/sign-up"
                />
            ),
        },
        {
            path: "/Portal",
            element: <Portal />,
        },
        // {
        //     path: "/GriefTalkBlog",
        //     element: <GriefTalkBlog />,
        // },
    ]);
    return (
        <ClerkProvider
            afterSignInUrl={window.location.href + "Portal"}
            allowedRedirectOrigins={[window.location.href + "Portal"]}
            publishableKey={clerkPublishableKey}
            navigate={(to) => router.navigate(to)}
        >
            <div className="App container">
                <RouterProvider router={router} />
            </div>
        </ClerkProvider>
    );
}

export default App;
